
.education-wrapper {
    height: 100%;
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
        .el-scrollbar__view {
            padding: 30px;
        }
    }
}
.steps-wrapper {
    margin-top: 70px;
}
.create-content {
    margin: 60px 0;
}
.success-content {
    margin-top: 155px;
    text-align: center;
    .iconfont {
        color: #1FD18C;
        font-size: 52px;
    }
    .text {
        font-size: 24px;
        margin: 20px 0 0;
        font-weight: bold;
    }
}
.bottom-btn {
    text-align: center;
}
.stepTwo-head {
    display: flex;
    justify-content: space-between;
    .text {
        margin: 0 10px 0 20px;
    }
}
.goods-cover {
    width: 44px;
    height: 44px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 1px solid #eee;
    background: #fafafa;
    img {
        max-width: 100%;
        max-height: 100%;
    }
}
.symbol-style {
    margin-left: 6px;
}
.wavy-line {
    line-height: 40px;
    margin: 0 7px 0 6px;
}
::v-deep .el-input-number {
    .el-input__inner {
        text-align: left;
    }
}

::v-deep .attr-table {
    .row-expand-last {
        border-right: 0;
    }
    .row-expand {
        .cell {
            display: none;
        }
    }
    .el-table__expanded-cell {
        border-bottom: 0;
        padding: 0;
        .el-table {
            .row-one {
                border-right: 0;
            }
            .row-last {
                border-right: 0;
            }
        }
    }
}
.el-link + .el-link {
    margin-left: 10px;
}
